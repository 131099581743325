import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import logo from '../../img/logohome2.png'; // Update this with DAZZ's logo if needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="DAZZ Logo" className="footer-logo" /> {/* Update alt text for DAZZ */}
          <p>Web Development & Graphic Design Services.</p> {/* Updated description */}
        </div>
        <div className="footer-menu">

          <ul>
            <li><Link to="/home">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/Realisations">Realisations</Link></li> {/* Updated link text */}
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="footer-contact">
          <h3>Contact Details</h3> {/* Updated heading */}
          <p><FontAwesomeIcon icon={faEnvelope} /> dazz.services@gmail.com</p> {/* Updated email */}
          <p><FontAwesomeIcon icon={faXTwitter} /> Dazz_Design</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Dazzdesign.xyz - All rights reserved.</p> {/* Updated copyright */}
      </div>
    </footer>
  );
}

export default Footer;
