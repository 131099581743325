import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './Dashboard.css';

const STATUS_OPTIONS = [
  { value: 'waiting', label: 'Waiting', color: '#ff4d4d' }, // Red
  { value: 'in-progress', label: 'In Progress', color: '#ffa500' }, // Orange
  { value: 'completed', label: 'Completed', color: '#28a745' } // Green
];

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({
    title: '',
    startDate: '',
    endDate: '',
    steps: '',
    userEmail: ''
  });
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState([]);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('waiting'); // Default status
  const auth = getAuth();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectCollection = collection(db, 'projects');
        const projectSnapshot = await getDocs(projectCollection);
        const projectList = projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        if (auth.currentUser?.email === 'dazz.services@gmail.com') {
          setProjects(projectList);
          if (users.length === 0) {
            const userCollection = collection(db, 'users');
            const userSnapshot = await getDocs(userCollection);
            const userList = userSnapshot.docs.map(doc => doc.data().email);
            setUsers(userList);
          }
        } else {
          const filteredProjects = projectList.filter(project => project.userEmail === auth.currentUser.email);
          setProjects(filteredProjects);
        }
      } catch (err) {
        console.error('Error fetching projects:', err);
      }
    };

    fetchProjects();
  }, [auth.currentUser, users]);

  const handleAddProject = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }

    try {
      const projectCollection = collection(db, 'projects');
      await addDoc(projectCollection, {
        ...newProject,
        userEmail: newProject.userEmail, // Use the userEmail field
        steps: selectedStatus // Save the status instead of steps
      });
      setNewProject({ title: '', startDate: '', endDate: '', steps: '', userEmail: '' }); // Reset form
      setSelectedStatus('waiting'); // Reset status
      alert('Project added successfully');
      const projectSnapshot = await getDocs(projectCollection);
      const projectList = projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectList);
    } catch (err) {
      console.error('Error adding project:', err);
    }
  };

  const handleUpdateSteps = async (projectId) => {
    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }

    try {
      const projectDoc = doc(db, 'projects', projectId);
      await updateDoc(projectDoc, { steps: selectedStatus });
      setEditingProjectId(null); // Exit editing mode
      setSelectedStatus('waiting'); // Reset status
      const projectSnapshot = await getDocs(collection(db, 'projects'));
      const projectList = projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectList);
    } catch (err) {
      console.error('Error updating steps:', err);
    }
  };

  const handleDeleteProject = async (projectId) => {
    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }

    try {
      const projectDoc = doc(db, 'projects', projectId);
      await deleteDoc(projectDoc);
      // Refresh the list of projects after deletion
      const projectSnapshot = await getDocs(collection(db, 'projects'));
      const projectList = projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectList);
    } catch (err) {
      console.error('Error deleting project:', err);
    }
  };

  return (
    <div className="dashboard">
      <h2>Dashboard</h2>
      {auth.currentUser?.email === 'dazz.services@gmail.com' && users.length > 0 && (
        <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          <option value="">Select a user</option>
          {users.map(userEmail => (
            <option key={userEmail} value={userEmail}>{userEmail}</option>
          ))}
        </select>
      )}
      {auth.currentUser?.email === 'dazz.services@gmail.com' && (
        <form onSubmit={handleAddProject} className="form-add-project">
          <div className="input-group">
            <label htmlFor="title">Project Title</label>
            <input
              type="text"
              id="title"
              value={newProject.title}
              onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
              placeholder="Project title"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="start-date">Start Date</label>
            <input
              type="date"
              id="start-date"
              value={newProject.startDate}
              onChange={(e) => setNewProject({ ...newProject, startDate: e.target.value })}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="end-date">End Date</label>
            <input
              type="date"
              id="end-date"
              value={newProject.endDate}
              onChange={(e) => setNewProject({ ...newProject, endDate: e.target.value })}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="steps">Status</label>
            <select
              id="steps"
              className="status-select"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              required
            >
              {STATUS_OPTIONS.map((option) => (
                <option key={option.value} value={option.value} className="status-option">
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="userEmail">Email Address</label>
            <input
              type="email"
              id="userEmail"
              value={newProject.userEmail}
              onChange={(e) => setNewProject({ ...newProject, userEmail: e.target.value })}
              placeholder="User's email address"
              required
            />
          </div>
          <button type="submit" className="btn-submit">Add Project</button>
        </form>
      )}
      <div className="projects-list">
        {projects.length > 0 ? (
          projects.map((project) => (
            <div key={project.id} className="project-card">
              <h3>{project.title}</h3>
              <p>Start Date: {project.startDate}</p>
              <p>End Date: {project.endDate}</p>
              <p>Email: {project.userEmail}</p>
              <p className="steps-text" style={{ color: STATUS_OPTIONS.find(option => option.value === project.steps)?.color || '#000' }}>
                {STATUS_OPTIONS.find(option => option.value === project.steps)?.label || 'Not Defined'}
              </p>
              {editingProjectId === project.id ? (
                <div>
                  <select
                    className="status-select"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    {STATUS_OPTIONS.map((option) => (
                      <option key={option.value} value={option.value} className="status-option">
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="actions">
                    <button
                      className="btn-update"
                      onClick={() => handleUpdateSteps(project.id)}
                    >
                      Save
                    </button>
                    <button
                      className="btn-delete"
                      onClick={() => handleDeleteProject(project.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {auth.currentUser?.email === 'dazz.services@gmail.com' && (
                    <button
                      className="btn-update"
                      onClick={() => {
                        setEditingProjectId(project.id);
                        setSelectedStatus(project.steps);
                      }}
                    >
                      Edit Status
                    </button>
                  )}
                  <div className="actions">
                    {auth.currentUser?.email === 'dazz.services@gmail.com' && (
                      <button
                        className="btn-delete"
                        onClick={() => handleDeleteProject(project.id)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className='aucun'>No projects to display</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
