import React, { useEffect } from 'react';
import './services.css'; // Ensure you create a corresponding CSS file
import carrelage from '../img/home/onlysite.png'; // Replace with an appropriate image if necessary
import Mosaïque from '../img/home/logopc.png'; // Replace with an appropriate image if necessary
import Parement from '../img/home/nft.png'; // Replace with an appropriate image if necessary

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);

  return (
    <div className="services-container">
      <div className="services">
        <h1><span className="highlight">My Services</span></h1>
        <br />
        <p>
          Welcome to my services page! I specialize in creating <span className="highlight">web applications</span> and <span className="highlight">graphic designs</span>. Whether you're looking to enhance your online presence or bring a creative vision to life.
        </p>
        <div className="service">
          <img src={carrelage} alt="Web Development" className="image-reahome" />
          <div className="service-text">
            <h2><span className="highlight">Web Development</span></h2>
            <p>
              I offer comprehensive <span className="highlight">web development services</span> that encompass everything from <span className="highlight">front-end design</span> to <span className="highlight">back-end functionality</span>. With a focus on <span className="highlight">React</span> and modern web technologies, I create responsive and interactive websites tailored to your needs.
            </p>
          </div>
        </div>
        <div className="service">
          <img src={Mosaïque} alt="Graphic Design" className="image-reahome" />
          <div className="service-text">
            <h2><span className="highlight">Graphic Design</span></h2>
            <p>
              My <span className="highlight">graphic design services</span> cover a range of needs from <span className="highlight">branding</span> to <span className="highlight">visual content creation</span>. Using tools like <span className="highlight">Adobe Creative Suite</span>, I craft visually compelling designs that effectively communicate your brand's message. From <span className="highlight">logos</span> to <span className="highlight">social media graphics</span>, I bring your vision to life with creativity and precision.
            </p>
          </div>
        </div>
        <div className="service">
          <img src={Parement} alt="Web3 & NFTs" className="image-reahome" />
          <div className="service-text">
            <h2><span className="highlight">Web3 & NFTs</span></h2>
            <p>
              <span className="highlight">Explore</span> the exciting world of <span className="highlight">NFTs</span> and <span className="highlight">Web3</span> technologies. I offer development services to create <span className="highlight">decentralized applications</span> and <span className="highlight">unique digital assets</span>. Whether you want to <span className="highlight">launch a custom NFT</span> or <span className="highlight">deploy a token</span>, I provide innovative solutions using tools like <span className="highlight">LayerZero</span> and <span className="highlight">Thirdweb</span> to meet your specific needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
