import React, { useEffect, useState } from 'react';
import './contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

import emailjs from 'emailjs-com';



const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs.send('service_lml0r54', 'template_5j685r8', formData, 'JUSZVErtGL-5zlS-h')
      .then((response) => {
        console.log('Email sent successfully', response);
        setSuccessMessage('Your message has been sent successfully!');
        setFormData({ name: '', email: '', phone: '', message: '' });
      })
      .catch((err) => {
        console.error('Failed to send email', err);
        setSuccessMessage('Failed to send the message. Please try again.');
      });
  };

  return (
    <div className="contact-container">
      <header className="contact-header">
        <h1><span className="highlight">Get in Touch with DAZZ</span></h1>
        <p className="contact-subtitle">
          For any inquiries or quotes, please fill out the form below.
        </p>
      </header>

      <form className="contact-form" onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="name" 
          placeholder="Name" 
          className="contact-input" 
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input 
          type="email" 
          name="email" 
          placeholder="Email" 
          className="contact-input" 
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input 
          type="tel" 
          name="phone" 
          placeholder="Phone Number" 
          className="contact-input" 
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <textarea 
          name="message" 
          placeholder="Message" 
          className="contact-textarea"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <button type="submit" className="submit-button">Send</button>
      </form>

      {successMessage && <p className="contact-thankyou">{successMessage}</p>}

      <div className="contact-info">
        <p>DAZZ</p>
        <p><FontAwesomeIcon icon={faEnvelope} /> dazz.services@gmail.com</p> {/* Updated email */}
        <p><FontAwesomeIcon icon={faXTwitter} /> Dazz_Design</p>

        </div>
    </div>
  );
};

export default Contact;
