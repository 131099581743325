import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './about.css';

const About = () => {
  const navigate = useNavigate();

  const handleContact = () => {
    navigate('/contact');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-container">
      <h1><span className="highlight">About Me</span></h1>
      <div className="content">
        <div className="text-container">
          <p>
            Hello,
            <br /><br />
            I'm <span className="highlight">Dazz</span>, a passionate web developer and graphic designer. My journey began in commerce, where I honed skills in management and customer service. However, my true calling led me to the digital realm, where I could blend <span className="highlight">technology</span>, <span className="highlight">design</span>, and <span className="highlight">creativity</span>.
            <br /><br />
            With a BTEC degree in Digital Systems, specializing in electronics and computer science, I have immersed myself in both hardware and software landscapes, exploring numerous programming languages and technologies. I utilize modern frameworks to create responsive and dynamic web applications.
            <br /><br />
            My passion for <span className="highlight">Web3</span> and <span className="highlight">NFTs</span> continuously inspires me to push my boundaries and explore new opportunities.
            <br /><br />
            As a graphic designer, my strength lies in understanding my clients' expectations. I believe every project is a unique opportunity to blend <span className="highlight">innovation</span> with <span className="highlight">artistry</span>.
            <br /><br />
            I'm eager to take on new challenges. Feel free to reach out to discuss your project or to request a <span className="highlight">free consultation</span> with no obligation.
          </p>
          <br />
          <button className="contact-button" onClick={handleContact}>
            Contact Me
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
