import React, { useState, useEffect } from 'react';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { auth, googleProvider } from '../firebaseConfig';
import Dashboard from './Dashboard'; // Make sure the path is correct
import './ClientSpace.css';

function ClientSpace() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [isBusiness, setIsBusiness] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState(null);
  const [isSignup, setIsSignup] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      console.log('Google login successful');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('Logout successful');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setSuccess("Signup successful!");
      setError('');
      setFirstName('');
      setLastName('');
      setCompany('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError(err.message);
      setSuccess('');
    }
  };

  const displayName = user?.displayName || (isBusiness ? company : `${firstName} ${lastName}`);
  const displayEmail = user?.email;

  return (
    <div className="client-space">
      <h2>Client Space</h2>
      {user ? (
        <div>
          <div className="user-info">
            <p className="welcome-message">Welcome <span className="user-name">{displayName}!</span></p>
            {displayEmail && <p className="user-email">{displayEmail}</p>}
            <button onClick={handleLogout} className="btn-logout">Logout</button>
          </div>
          <Dashboard /> {/* Dashboard integration */}
        </div>
      ) : (
        <div className="auth-forms">
          {isSignup ? (
            <form onSubmit={handleSignup} className="form-signup">
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="confirm-password">Confirm Password</label>
                <input
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your password"
                  required
                />
              </div>
              {!isBusiness ? (
                <>
                  <div className="input-group">
                    <label htmlFor="first-name">First Name</label>
                    <input
                      type="text"
                      id="first-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="last-name">Last Name</label>
                    <input
                      type="text"
                      id="last-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </>
              ) : (
                <div className="input-group">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="Enter your company name"
                    required
                  />
                </div>
              )}
              <div className="input-group">
                <label>
                  <input
                    type="checkbox"
                    checked={isBusiness}
                    onChange={() => setIsBusiness(prev => !prev)}
                  />
                  {' '}
                  Business Account
                </label>
              </div>
              <button type="submit" className="btn-submit">Sign Up</button>
              {error && <p className="error">{error}</p>}
              {success && <p className="success">{success}</p>}
              <p>
                Already registered?{' '}
                <button type="button" onClick={() => setIsSignup(false)} className="btn-toggle">Log In</button>
              </p>
            </form>
          ) : (
            <div>
              <button onClick={handleGoogleLogin} className="btn-google">Log in with Google</button>
              <form onSubmit={handleLogin} className="form-login">
                <div className="input-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                </div>
                <button type="submit" className="btn-submit">Log In</button>
                {error && <p className="error">{error}</p>}
                <p>
                  Not registered yet?{' '}
                  <button type="button" onClick={() => setIsSignup(true)} className="btn-toggle">Sign Up</button>
                </p>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ClientSpace;
