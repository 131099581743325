import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './home.css';
import carrelage from '../img/home/site.gif';
import nft from '../img/home/nft.png';
import onlysite from '../img/home/onlysite.png';
import logopc from '../img/home/logopc.png';

// Données des projets pour le carrousel
const projects = [
  {
    title: 'Web Development',
    description: 'Websites ensuring a seamless user experience across all devices.',
    img: onlysite,
  },
  {
    title: 'Graphic Design',
    description: 'Designed a distinctive logo for a strong visual identity.',
    img: logopc,
  },
  {
    title: 'Web3 & NFT Projects',
    description: 'NFT "The French Pepe" for the launch of the Monad Discord.',
    img: nft,
  },
];

const Home = () => {
  const navigate = useNavigate();

  const handleProjects = () => {
    navigate('/realisations'); // Navigate to the Projects page
  };

  // Paramètres du carrousel
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Activer le défilement automatique
    autoplaySpeed: 3000, // Vitesse du défilement automatique (en ms)
    arrows: true, // Activer les flèches de navigation
    centerMode: true, // Centrer les éléments
    centerPadding: '20px', // Espacement autour de l'élément central
  };

  return (
    <div className="home-container">
      {/* Introduction principale */}
      <h1>Web Development & Graphic Design</h1>
      <h3>Create unique digital experiences that blend technology and creativity.</h3>

      {/* Présentation des services */}
      <div className="home-images">
        <img src={carrelage} alt="Web Development" className="home-image" />
      </div>

      {/* Garantie de qualité */}
      <h3>
        Whether you're starting a new project or enhancing an existing one,<br/>
        <span className="highlight">I guarantee quality work that meets your expectations.</span>
      </h3>

      <br/>
      <br/>

      {/* Section des projets avec carrousel */}
      <div className="lowbackground">
        <h1>Some Projects</h1>
        <br/>
        <br/>
        <div className="carousel-container">
          <Slider {...carouselSettings}>
            {projects.map((project, index) => (
              <div key={index} className="carousel-item">
                <img src={project.img} alt={project.title} className="carousel-image" />
                <h2>{project.title}</h2>
                <p>{project.description}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Section de l'image et bouton "Discover My Work" */}
      <div className="image-container">
        <br/>
        <br/>
        <button className="overlay-button" onClick={handleProjects}>
          Discover My Work
        </button>
      </div>
    </div>
  );
};

export default Home;
