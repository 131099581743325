import React, { useState, useEffect } from 'react';
import './realisation.css';

import img1a from '../img/chantiers/1a.png';
import img2a from '../img/chantiers/2a.png';
import img3a from '../img/chantiers/3a.png';
import img4a from '../img/chantiers/4a.png';
import img5a from '../img/chantiers/5a.png';
import img6a from '../img/chantiers/6a.gif';



const Realisation = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const projects = [
    {
      title: 'Web Development',
      description: "Website ensuring a seamless user experience across all devices.",
      images: [img1a],
    },
    {
      title: 'Graphic Design',
      description: "Designed a distinctive logo for a strong visual identity.",
      images: [img2a],
    },
    {
      title: 'Web3 Projects',
      description: "Creation of the $BRRR Mascot on Starknet",
      images: [img3a],
    },
    {
      title: 'NFT Projects',
      description: "NFT The French Pepe for the launch of the Monad Discord.",
      images: [img4a],
    },
    {
      title: 'Branding Package',
      description: "Designed a comprehensive branding package including logo, color scheme, and website of $pointless",
      images: [img5a],
    },
    {
      title: 'NFT Art',
      description: "CHETHEREUM presented in the #welovetheart contest by Optimism",
      images: [img6a],
    },
  ];

  const handleImageClick = (projectIndex, imageIndex) => {
    setSelectedProject(projectIndex);
    setSelectedImageIndex(imageIndex);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setSelectedProject(null);
    setSelectedImageIndex(0);
  };

  const selectThumbnail = (index) => {
    setSelectedImageIndex(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="realisation-container">
      <h1><span className="highlight">My Realisations</span></h1>
      <p>Welcome to my gallery!</p> 
      <br/>
      <br/>
      <div className="projets">
        {projects.map((project, projectIndex) => (
          <div key={projectIndex} className="projet">
            <img
              src={project.images[0]}
              alt={project.title}
              className="projet-image"
              onClick={() => handleImageClick(projectIndex, 0)}
            />
            <h2>{project.title}</h2>
            <p>{project.description}</p>
          </div>
        ))}
      </div>

      {selectedProject !== null && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>&times;</button>
            <img
              src={projects[selectedProject].images[selectedImageIndex]}
              alt="Enlarged View"
              className="modal-image"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Realisation;
